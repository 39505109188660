<template>
  <div class="tree-skeleton">
    <div class="tree-box">
      <template v-for="key in lines">
        <div :key="key" class="tree-node"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'treeSkeleton',
  props: {
    lines: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-skeleton {
  .tree-box {
    .tree-node {
      height: 36px;
      margin-bottom: 0.5rem;
      background-color: var(--bg);
      border-radius: var(--border-radius);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
