import axios from '@/http/axios'
import router from '@/router/router'

const tenant = router.currentRoute.params.hasOwnProperty('tenant') ? router.currentRoute.params.tenant : 'tennis'

export function getProvinces (id) {

  return axios({
    url: `v1/app/{tenant}/countries/${id}/provinces`,
    method: 'get'
  })
}

export function getAllProvinces () {

  return axios({
    url: 'v1/app/{tenant}/provinces',
    method: 'get'
  })
}
export function getCountries () {

  return axios({
    url: 'v1/app/{tenant}/countries',
    method: 'get'
  })
}
