import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from '../axios/utils'
import router from '@/router/router'

const tenant = router.currentRoute.params.hasOwnProperty('tenant') ? router.currentRoute.params.tenant : 'tennis'

const cancelTokenHandlerObject = {
  getSaleInvoices: createCancelTokenHandler('getSaleInvoices'),
  getSaleInvoicesPrice: createCancelTokenHandler('getSaleInvoicesPrice'),
  getSaleInvoiceTrash: createCancelTokenHandler('getSaleInvoiceTrash'),
  getSaleInvoiceActiveStocks: createCancelTokenHandler('getSaleInvoiceActiveStocks'),
  getSuggestSaleInvoice: createCancelTokenHandler('getSuggestSaleInvoice')
}

export function getSaleInvoices (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoices'].handleRequestCancellation().token
  })
}

export function getSaleInvoicesPrice (filters = {}) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/total-invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoicesPrice'].handleRequestCancellation().token
  })
}

export function exportSaleInvoices (filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/invoices?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function getSaleInvoiceTrash (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/invoices?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoiceTrash'].handleRequestCancellation().token
  })
}

export function getSuggestSaleInvoice (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/suggest-invoice${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSuggestSaleInvoice'].handleRequestCancellation().token
  })
}

export function getSaleInvoiceActiveStocks (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/active-stocks${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoiceActiveStocks'].handleRequestCancellation().token
  })
}

export function exportSuggestSale (filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/sales/suggest-invoice?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function createSaleInvoiceDocument (id) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/document`,
    method: 'post'
  })
}

export function getSaleInvoice (payload) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${payload.id}`,
    method: 'get'
  })
}

export function exportSaleInvoice (id, type = 'pdf') {
  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/export`,
    method: 'post',
    responseType: 'blob'
  })
}

export function getSaleInvoiceActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function getSaleGiftAccounts () {
  return axios({
    url: 'v1/app/{tenant}/sales/gift-accounts',
    method: 'get'
  })
}

export function insertSaleInvoice (payload, force = false) {
  return axios({
    url: `v1/app/{tenant}/sales/invoices${force ? '?force=true' : ''}`,
    data: payload,
    method: 'post'
  })
}

export function editSaleInvoice (payload, force = false) {

  return axios.patch(`v1/app/{tenant}/sales/invoices/${payload.id}${force ? '?force=true' : ''}`, payload)
}

export function refreshSaleInvoiceStatus (payload) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${payload.id}/refresh-status`,
    data: payload,
    method: 'post'
  })
}

export function completeSaleInvoice (id) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/complete`,
    method: 'post'
  })
}

export function sendMailSaleInvoice (id, payload) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/send-mail`,
    data: payload,
    method: 'post'
  })
}

export function deleteSaleInvoice (id, force = false) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreSaleInvoice (id) {

  return axios({
    url: `v1/app/{tenant}/sales/invoices/${id}/restore`,
    method: 'put'
  })
}
