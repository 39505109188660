import axios from '@/http/axios'
import router from '@/router/router'

// avatars
export function uploadAssets (data, type = 'avatars', routeInfo = {}) {
  if ((router.currentRoute.params.hasOwnProperty('tenant') && router.currentRoute.params.tenant) || (routeInfo.params && routeInfo.params.hasOwnProperty('tenant') && routeInfo.params.tenant)) {
    return axios({
      url: `v1/app/{tenant}/assets/${type}/upload`,
      data,
      paramData: {tenant: routeInfo.params && routeInfo.params.hasOwnProperty('tenant') ? routeInfo.params.tenant : ''},
      method: 'post'
    })
  } else {
    return axios({
      url: `assets/${type}/upload`,
      data,
      method: 'post'
    })
  }
}

export function uploadAttachment (data, type = 'avatars', routeInfo = {}) {
  if ((router.currentRoute.params.hasOwnProperty('tenant') && router.currentRoute.params.tenant) || (routeInfo.params && routeInfo.params.hasOwnProperty('tenant') && routeInfo.params.tenant)) {
    return axios({
      url: `v1/app/{tenant}/assets/attachments/${type}/upload`,
      data,
      paramData: {tenant: routeInfo.params && routeInfo.params.hasOwnProperty('tenant') ? routeInfo.params.tenant : ''},
      method: 'post'
    })
  } else {
    return axios({
      url: `assets/attachments/${type}/upload`,
      data,
      method: 'post'
    })
  }
}
