import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from '../axios/utils'
import router from '@/router/router'

const tenant = router.currentRoute.params.hasOwnProperty('tenant') ? router.currentRoute.params.tenant : 'tennis'

const cancelTokenHandlerObject = {
  getPurchaseInvoices: createCancelTokenHandler('getPurchaseInvoices'),
  getPurchaseInvoicesPrice: createCancelTokenHandler('getPurchaseInvoicesPrice'),
  getPurchaseInvoiceTrash: createCancelTokenHandler('getPurchaseInvoiceTrash'),
  getPurchaseInvoiceActiveStocks: createCancelTokenHandler('getPurchaseInvoiceActiveStocks'),
  getSuggestPurchaseInvoice: createCancelTokenHandler('getSuggestPurchaseInvoice')
}

export function getPurchaseInvoices (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPurchaseInvoices'].handleRequestCancellation().token
  })
}
export function getPurchaseInvoicesPrice (filters = {}) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/total-invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPurchaseInvoicesPrice'].handleRequestCancellation().token
  })
}

export function exportPurchaseInvoices (filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/invoices?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function getPurchaseInvoiceTrash (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/invoices?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPurchaseInvoiceTrash'].handleRequestCancellation().token
  })
}

export function getSuggestPurchaseInvoice (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/suggest-invoice${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSuggestPurchaseInvoice'].handleRequestCancellation().token
  })
}

export function getPurchaseInvoiceActiveStocks (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}/active-stocks${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPurchaseInvoiceActiveStocks'].handleRequestCancellation().token
  })
}

export function exportSuggestPurchase (filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/suggest-invoice?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function getPurchaseInvoiceActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}/activity-logs${queries}`,
    method: 'get'
  })
}


export function insertPurchaseInvoice (payload, filters = {}) {
  let queries = makeQuery(1, filters, [])

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/purchases/invoices${queries}`,
    data: payload,
    method: 'post'
  })
}

export function getPurchaseInvoice (payload) {

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${payload.id}`,
    method: 'get'
  })
}

export function exportPurchaseInvoice (id, type = 'pdf') {
  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}/export`,
    method: 'post',
    responseType: 'blob'
  })
}

export function createPurchaseInvoiceDocument (id) {

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}/document`,
    method: 'post'
  })
}

export function editPurchaseInvoice (payload, filters = {}, force = false) {
  let queries = makeQuery(1, filters, [])
  if (force) {
    queries.push('force=true')
  }

  if (queries.length > 0) queries = `?${  queries.join('&')}`


  return axios.patch(`v1/app/{tenant}/purchases/invoices/${payload.id}${queries}`, payload)
}

export function editPurchaseInvoiceLine (id, payload, filters = {}, force = false) {
  let queries = makeQuery(1, filters, [])
  if (force) {
    queries.push('force=true')
  }

  if (queries.length > 0) queries = `?${  queries.join('&')}`


  return axios.patch(`v1/app/{tenant}/purchases/invoices/${id}/lines/${payload.id}${queries}`, payload)
}

export function refreshPurchaseInvoiceStatus (payload) {

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${payload.id}/refresh-status`,
    data: payload,
    method: 'post'
  })
}

export function deletePurchaseInvoice (id, force = false) {

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function deletePurchaseInvoiceLine (id, productId) {

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}/lines/${productId}`,
    method: 'delete'
  })
}

export function restorePurchaseInvoice (id) {

  return axios({
    url: `v1/app/{tenant}/purchases/invoices/${id}/restore`,
    method: 'put'
  })
}

