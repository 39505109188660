<template>
  <div class="input-box" :class="getClasses">
    <template v-if="type === 'date'">
      <custom-datepicker :format="$u.locale.validator('moment.date')"
                         v-model="value" />
    </template>
    <input type="text"
           :id="inputId.toString()"
           :class="[getClasses, currency ? 'has-currency' : '', {'not-valid': typeof initialValue === 'object' && !initialValue.isValid}]"
           :autocomplete="false"
           :name="Math.random()"
           :placeholder="getPlaceHolder"
           @focus="inputFocused"
           @contextmenu="handleContextMenuClicked($event)"
           @input="value = $u.trim($event.target.value), refactorValueType()"
           @keyup.enter="value = $u.trim($event.target.value), refactorValueType(), $emit('enter:keyUp', typeof initialValue === 'object' ? initialValue.value : value)"
           :value="value"
           :disabled="typeof initialValue === 'object' && initialValue.disabled"
           style="background: transparent !important;">

    <span v-if="currency" class="currency" @click="handleFocusInput">{{ currency.hasOwnProperty('label') ? currency.label : currency.hasOwnProperty('value') ? currency.value : currency }}</span>

    <vs-icon
      v-if="returnValue && value !== firstValue"
      class="undo-button"
      icon="replay"
      style="font-size: 17px;"
      @click="handleReturnValueClick"/>
  </div>
</template>

<script>
import DatePicker from 'vue-persian-datetime-picker'
import CustomDatepicker from '@/components/customDatepicker/customDatepicker.vue'

export default {
  name: 'InputBox',
  model: {
    prop: 'initialValue',
    event: 'inputValueChange'
  },
  props: {
    initialValue: {
      type: [String, Object, Number],
      default: ''
    },
    align: {
      type: String,
      default: 'left'
    },
    type: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    returnValue: {
      type: Boolean,
      default: true
    }
  },
  components: {CustomDatepicker, DatePicker},
  data () {
    return {
      value: '',
      firstValue: '',
      inputId: ''
    }
  },
  computed: {
    getClasses () {
      const classes = []
      switch (this.type) {
      case 'price':
      case 'number':
        classes.push('price-style')
        if (!this.align) {
          classes.push('text-right')
        }
        break
      }

      if (this.align) {
        classes.push(`text-${this.align}`)
      }

      if (this.returnValue) {
        classes.push('return-value')
      }
      return classes
    },
    getPlaceHolder () {
      if (typeof this.initialValue === 'object') {
        if (this.type === 'price') {
          return this.$u.priceFormat((this.initialValue.placeHolder || '').toString(), true)
        }
        return (this.initialValue.placeHolder || '').toString() || ''
      } else return ''
    }
  },
  created () {
    if (this.initialValue) {
      if (typeof this.initialValue === 'object') {
        this.value = this.initialValue.value
        this.firstValue = this.initialValue.value
      } else {
        this.value = this.initialValue
        this.firstValue = this.initialValue
      }
    }
    this.inputId = Math.random()
  },
  methods: {
    refactorValueType () {
      if (this.type) {
        switch (this.type) {
        case 'price':
          this.value = this.$u.priceFormat(this.value)
          break

        case 'number':
          this.value = this.value.toString().replace(/\D/g, '') || ''
          break
        }
      } else {
        switch (typeof this.initialValue) {
        case 'number':
          this.value = this.$u.parseFloat(this.value.replace(/\/r/g, '/')) || 0
          break
        }
      }
      if (typeof this.initialValue === 'object') {
        this.$emit('inputValueChange', {value: this.value, placeHolder: this.initialValue.placeHolder || '', isValid: this.initialValue.hasOwnProperty('isValid') ? this.initialValue.isValid : true})
      } else {
        this.$emit('inputValueChange', this.value)
      }
    },
    inputFocused (event) {
      this.$emit('focus')
      event.target.setSelectionRange(0, event.target.value.length)
    },
    handleFocusInput () {
      window.document.getElementById(this.inputId.toString()).focus()
    },
    updateFirstValue () {
      this.firstValue = this.value
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.value
        },
        event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleReturnValueClick () {
      this.value = this.firstValue.toString()
      this.refactorValueType()
    }
  },
  watch: {
    initialValue: {
      handler () {
        if (typeof this.initialValue === 'object') {
          if (this.value !== this.initialValue.value) {
            this.value = this.initialValue.value
            this.refactorValueType()
          }
        } else if (this.value !== this.initialValue) {
          this.value = this.initialValue
          this.refactorValueType()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  @import "src/assets/scss/vuexy/variables";

  .input-box {
    width: 100%;

    .vpd-input-group {
      width: 20px !important;
      position: absolute;
      top: calc(100% / 2 - 15px);
      left: 2px;

      .vpd-icon-btn {
        opacity: 1 !important;
        height: 25px !important;
        width: 20px !important;
        background-color: inherit !important;

        svg {
          fill: #000000;
          overflow: visible !important;
        }
      }

      .form-control {
        border: 0;
        width: 100%;
        display: none;
        line-height: 35px;
      }
    }

    input {
      height: 100%;
      direction: ltr;
      text-align: left;
      border: none;
      width: 100%;
      padding: 0 5px;
      transition: border 0.6s cubic-bezier(.48, .24, .36, .99);

      &.return-value {
        padding-right: 30px;
      }

      &.has-currency {
        padding-right: 70px;
      }

      &.not-valid {
        color: $danger;
      }

      /*&:focus {
        border-bottom: 1px solid #000;
      }*/
    }

    .currency {
      position: absolute;
      right: 38px;
      z-index: 100;
      color: #888888;
      font-size: 13px;
      font-weight: 400;
    }

    .undo-button {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
      cursor: pointer;
      color: #3891bc;
    }
  }

</style>
