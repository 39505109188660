import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from '../axios/utils'
import router from '@/router/router'

const tenant = router.currentRoute.params.hasOwnProperty('tenant') ? router.currentRoute.params.tenant : 'tennis'

const cancelTokenHandlerObject = {
  getTaxes: createCancelTokenHandler('getTaxes'),
  getTaxesTrash: createCancelTokenHandler('getTaxesTrash')
}

export function getAccounts () {
  return axios({
    url: 'v1/app/{tenant}/accountancy/accounts',
    method: 'get'
  })
}

export function insertAccount (payload) {
  return axios({
    url: 'v1/app/{tenant}/accountancy/accounts',
    data: payload,
    method: 'post'
  })
}

export function editAccount (id, payload) {
  return axios.patch(`v1/app/{tenant}/accountancy/accounts/${id}`, payload)
}

export function deleteAccount (id) {
  return axios({
    url: `v1/app/{tenant}/accountancy/accounts/${id}`,
    method: 'delete'
  })
}

export function getTaxes (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/accountancy/taxes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getTaxes'].handleRequestCancellation().token
  })
}

export function getTaxesTrash (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${queries.join('&')}`
  return axios({
    url: `v1/app/{tenant}/accountancy/taxes?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getTaxesTrash'].handleRequestCancellation().token
  })
}

export function getTax (id) {

  return axios({
    url: `v1/app/{tenant}/accountancy/taxes/${id}`,
    method: 'get'
  })
}

export function exportTaxes (filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/accountancy/taxes?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function exportTax (id, type = 'pdf') {
  return axios({
    url: `v1/app/{tenant}/accountancy/taxes/${id}/export`,
    method: 'post',
    responseType: 'blob'
  })
}

export function getTaxActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/app/{tenant}/accountancy/taxes/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertTax (payload) {
  return axios({
    url: 'v1/app/{tenant}/accountancy/taxes',
    data: payload,
    method: 'post'
  })
}

export function deleteTax (id, force = false) {
  return axios({
    url: `v1/app/{tenant}/accountancy/taxes/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreTax (id) {

  return axios({
    url: `v1/app/{tenant}/accountancy/taxes/${id}/restore`,
    method: 'put'
  })
}
