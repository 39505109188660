import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from '../axios/utils'
import router from '@/router/router'

const tenant = router.currentRoute.params.hasOwnProperty('tenant') ? router.currentRoute.params.tenant : 'tennis'

const cancelTokenHandlerObject = {
  getEvents: createCancelTokenHandler('getEvents')
}

export function getEvents (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getEvents'].handleRequestCancellation().token
  })
}

export function getEvent (id) {

  return axios({
    url: `v1/app/{tenant}/events/${id}`,
    method: 'get'
  })
}

export function insertEvent (payment) {

  return axios({
    url: 'v1/app/{tenant}/events',
    data: payment,
    method: 'post'
  })
}

export function seenEvent (id, subscriber_id) {

  return axios({
    url: `v1/app/{tenant}/events/${id}/subscribers/${subscriber_id}/seen`,
    method: 'post'
  })
}

export function seenAllEvents (force) {

  return axios({
    url: `v1/app/{tenant}/events/all/seen${force ? '?force=true' : ''}`,
    method: 'post'
  })
}
