import axios from '@/http/axios'
import store from '../../store/store'
import {createCancelTokenHandler, makeQuery} from '../axios/utils'
import router from '@/router/router'

const tenant = router.currentRoute.params.hasOwnProperty('tenant') ? router.currentRoute.params.tenant : 'tennis'

const cancelTokenHandlerObject = {
  getProfileReceivedEvents: createCancelTokenHandler('getProfileReceivedEvents'),
  getProfileReceivedEventsNavbar: createCancelTokenHandler('getProfileReceivedEventsNavbar'),
  getProfileSentEvents: createCancelTokenHandler('getProfileSentEvents')
}

/* Profile */
export function getProfile (routeInfo = {}) {
  if ((router.currentRoute.params.hasOwnProperty('tenant') && router.currentRoute.params.tenant) || (routeInfo.params && routeInfo.params.hasOwnProperty('tenant') && routeInfo.params.tenant)) {
    return axios({
      url: 'v1/app/{tenant}/profile',
      paramData: {tenant: routeInfo.params && routeInfo.params.hasOwnProperty('tenant') ? routeInfo.params.tenant : ''},
      method: 'get'
    })
  } else {
    return axios({
      url: 'v1/core/auth/profile',
      method: 'get'
    })
  }
}

export function editProfile (payment) {
  return axios.patch('v1/app/{tenant}/profile', payment)
}

export function getProfileReceivedEvents (page, filters = {}, sorts = [], navbar = false) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/profile/received-events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject[`getProfileReceivedEvents${navbar ? 'Navbar' : ''}`].handleRequestCancellation().token
  })
}

export function getProfileSentEvents (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/profile/sent-events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileSentEvents'].handleRequestCancellation().token
  })
}

export function getProfileAddresses (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/app/{tenant}/profile/addresses${queries}`,
    method: 'get'
  })
}

export function insertProfileAddress (payload) {

  return axios({
    url: 'v1/app/{tenant}/profile/addresses',
    data: payload,
    method: 'post'
  })
}

export function editProfileAddress (id, payment) {
  return axios.patch(`v1/app/{tenant}/profile/addresses/${id}`, payment)
}

export function deleteProfileAddress (id) {
  return axios({
    url: `v1/app/{tenant}/profile/addresses/${id}`,
    method: 'delete'
  })
}

export function editProfileBrowserSettings (payload = {}) {
  const browser = JSON.parse(store.state.auth.user.browser_setting)
  const setting = {
    ...payload,
    tables: store.state.table.tables,
    // tables: {},
    // tables: store.state.table.tables.storesListTable ? store.state.table.tables.storesListTable : [],
    theme: store.state.theme
  }
  if (!setting.hasOwnProperty('mainChart')) {
    const mainChart = browser.mainChart
    if (browser.hasOwnProperty('mainChart')) {
      setting.mainChart = mainChart
    }
  }
  delete setting.tables.tables
  delete setting.tables.theme
  store.dispatch('auth/updateUserInfo', {browser_setting: JSON.stringify(setting) })
  // console.log(JSON.stringify(setting))
  // console.log(setting)
  return axios.patch('v1/app/{tenant}/profile/browser-settings', { settings: JSON.stringify(setting) })
}

export function getProfileActiveSessions () {
  return axios({
    url: 'v1/core/auth/profile/active-sessions',
    method: 'get'
  })
}

export function revokeActiveSession (id) {
  return axios({
    url: `v1/core/auth/profile/active-sessions/${id}`,
    method: 'delete'
  })
}
