import axios from '@/http/axios'
import router from '@/router/router'

export function loginUser (credentials) {
  if (!credentials.super_admin) return axios({
    url: 'v1/core/auth/login',
    data: {
      phone_number: credentials.phoneNumber,
      code: credentials.otpCode
    },
    method: 'post'
  })

  else return axios({
    url: 'v1/core/auth/login',
    data: {
      super_admin: credentials.super_admin,
      phone_number: credentials.phoneNumber,
      token: credentials.token
    },
    method: 'post'
  })

}

export function sendOTPCode (phoneNumber) {
  return axios({
    url: 'v1/core/auth/send-otp',
    data: {
      phone_number: phoneNumber
    },
    method: 'post'
  })
}

export function resendOTPCode (phoneNumber, voice) {
  return axios({
    url: 'v1/core/auth/send-otp',
    data: {
      phone_number: phoneNumber,
      voice
    },
    method: 'post'
  })
}

export function logoutUser () {
  return axios({
    url: 'v1/core/auth/logout',
    method: 'post'
  })
}

export function getCompanyInfo (routeInfo = {}) {
  if ((router.currentRoute.params.hasOwnProperty('tenant') && router.currentRoute.params.tenant) || (routeInfo.params && routeInfo.params.hasOwnProperty('tenant') && routeInfo.params.tenant)) {
    return axios({
      url: 'v1/app/{tenant}/company/info',
      paramData: {tenant: routeInfo.params && routeInfo.params.hasOwnProperty('tenant') ? routeInfo.params.tenant : ''},
      method: 'get'
    })
  } else {
    return axios({
      url: 'v1/core/company/info',
      method: 'get'
    })
  }
}
