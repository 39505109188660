import axios from 'axios'
import Vue from 'vue'
import store from '@/store/store'
import { loading } from '@/prototypes/u/loading'
import { getReadingTime } from '@/prototypes/u/other'
import router from '@/router/router'

const protocol = window.location.protocol
let domain = window.location.hostname
domain = window.location.hostname.split('.').reverse()
if (domain.length > 3) domain = domain.splice(0, 3)
domain = domain.reverse().join('.')
domain = `${protocol}//${domain}/api`

const baseURL = process.env.VUE_APP_BASE_URL || domain
const API = axios.create({
  baseURL
  // You can add your headers here
})

const cancelToken = axios.CancelToken

const $t = sign => Vue.prototype.i18n.t(sign)

API.interceptors.request.use(function (config) {
  // Do something before request is sent

  // throw new API.Cancel('you send sevaral request')
  // console.log(new cancelToken((cancel) => cancel('cancel repeat')).reason.message)

  config.headers['Cache-Control'] = 'no-store'
  config.headers['Content-Security-Policy'] = 'default-src \'self\'; script-src \'self\'; style-src \'self\'; font-src \'self\'; img-src \'self\'; frame-src \'self\''

  const exceptUrls = ['v1/app/{tenant}/profile/browser-settings']

  if (config.method !== 'get' && exceptUrls.indexOf(config.url.split('?')[0]) === -1 && config.url.indexOf('loading=false') === -1) {
    loading.show()
  }

  let tenant = ''
  if (router.currentRoute.params.hasOwnProperty('tenant')) {
    tenant = router.currentRoute.params.tenant || ''
  } else if (router.history.pending && router.history.pending.params && router.history.pending.params.tenant) {
    tenant = router.history.pending.params.tenant || ''
  } else if (config.paramData && config.paramData.tenant) {
    tenant = config.paramData.tenant
  }
  config.url = config.url.replace('{tenant}', tenant)

  return config

}, function (error) {
  // Do something with request error
  // console.log('rejected')
  return Promise.reject(error)
})

API.interceptors.response.use(function (response) {
  // console.log(response.config)
  if (response.config.method !== 'get' && response.config.url.indexOf('loading=false') === -1) {
    loading.hide()
  }

  if (response.headers.date) {
    const date = response.headers.date.split(',')
    store.dispatch('helper/changeCurrentTime', date[1])
  }
  return response
}, function (error) {
  const loggedIn = localStorage.getItem('accessToken')

  loading.hide()

  if (loggedIn && error.response && error.response.status === 401) {
    store.dispatch('auth/noAccessUser')
  }
  // console.log(store.state.helper.requestStatus)
  if (loggedIn && error.response && error.response.status === 403) {
    Vue.prototype.$vs.notify({
      title: Vue.prototype.noAccessNotify.title,
      text: Vue.prototype.noAccessNotify.text,
      icon: 'icon-alert-circle',
      iconPack: 'feather',
      time: getReadingTime(Vue.prototype.noAccessNotify.text),
      color: 'danger'
    })
    // store.dispatch('auth/noAccessUser')
  }

  if (error.response && error.response.status === 423) {
    const key = error.response.data.message.split(':')[0]
    switch (key) {
    case 'opening_inventory_is_unlocked':
      Vue.prototype.$vs.notify({
        title: $t('alert.error.title'),
        text: $t('alert.error.openingBalanceUnlock'),
        icon: 'icon-alert-circle',
        iconPack: 'feather',
        time: getReadingTime($t('alert.error.openingBalanceUnlock')),
        color: 'danger'
      })
      break

    case 'active_session_locked':
    case 'opening_inventory_is_locked':

      break

    default:
      Vue.prototype.$vs.notify({
        title: $t('alert.error.title'),
        text: $t('alert.error.contactSupport'),
        icon: 'icon-alert-circle',
        iconPack: 'feather',
        time: getReadingTime($t('alert.error.contactSupport')),
        color: 'danger'
      })
      break
    }
  }
  return Promise.reject(error)
})


export default API
